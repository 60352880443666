<template>
  <v-card flat tile>
    <v-toolbar class="pt-3 header_style" extended extension-height="15">
      <div v-if="getProductBrandLogo()">
        <img :src="getProductBrandImageUrl()" class="header_logo_style" alt="logo"/>
      </div>
      <v-toolbar-title></v-toolbar-title>
      <div class="flex-grow-1"></div>
    </v-toolbar>
  </v-card>
</template>

<script>
import { apiUrl } from '../_config'

export default {
  name: 'Header',
  methods: {
    getProductBrandImageUrl: function () {
      return apiUrl() + 'media/logo/' + this.getProductBrandLogo()
    },
    getProductBrandLogo: function () {
      if (this.$cookies.get('standard_dashboard_assessment_logo') && this.$cookies.get('standard_dashboard_assessment_logo').trim() !== '') {
        return this.$cookies.get('standard_dashboard_assessment_logo')
      }
      return undefined
    }
  }
}
</script>
<style>
  .header_style {
    background: #f5f5f5 !important;
  }
  .header_logo_style{
    height: 65px;
  }
</style>
