<template>
  <v-footer :class="[overrideColor]">
    <div v-if="!overrideCopyRight" class="footer-font">© 2017- {{ new Date().getFullYear() }} Global Psytech Sdn. Bhd.</div>
    <div v-else-if="overrideCopyRight==='GFI'" class="footer-font">© 2017- {{ new Date().getFullYear() }} GFI Fintech.</div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      overrideColor: '',
      overrideCopyRight: ''
    }
  },
  created: function () {
    if (this.$cookies.get('standard_dashboard_override_color') && this.$cookies.get('standard_dashboard_override_color').trim() !== '') {
      this.overrideColor = this.$cookies.get('standard_dashboard_override_color')
    }
    if (this.$cookies.get('standard_dashboard_copyright') && this.$cookies.get('standard_dashboard_copyright').trim() !== '') {
      this.overrideCopyRight = this.$cookies.get('standard_dashboard_copyright')
    }
  }
}
</script>

<style>
  .footer-font {
    font-weight: 400;
  }

  .bnm .footer-font {
    font-weight: 200;
  }
</style>
